﻿.footer-content {
	padding: rem(50px) 0 rem(45px);
	font-size: rem(16px);

	&__item {
		padding: rem(41px) 0 rem(20px) 0;

		&.-logo {
			position: relative;
			padding-top: rem(155px);

			&:before {
				position: absolute;
				content: '';
				width: rem(270px);
				height: rem(52px);
				top: rem(48px);
				left: 0;
				background: url(../../images/logo-nuijten.png) no-repeat center bottom;
				background-image: url(../../images/logo-nuijten.svg), none;
			}
		}
	}

	&__links {
		@extend .side-line;
		@extend .-border;
		@extend .-small;
		padding: 0 0 0 rem(20px);

		&:before {
			width: 1px;
			background: $color-border;
		}
	}

	&__link {
		color: $color-primary;
		text-decoration: none;
		display: block;

		&.-bold {
			font-weight: bold;
		}

		&.-no-style {
			color: #000;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}
