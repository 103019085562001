﻿$icon-arrow-bold-down: "\ea01";
$icon-arrow-bold-left: "\ea02";
$icon-arrow-bold-right: "\ea03";
$icon-arrow-bold-up: "\ea04";
$icon-external: "\ea05";
$icon-facebook: "\ea06";
$icon-instagram: "\ea07";
$icon-pinterest: "\ea08";
$icon-twitter: "\ea09";
$icon-youtube: "\ea0a";
$icon-mail: "\ea0b";
$icon-pointer: "\ea0c";
$icon-telephone: "\ea0d";

@at-root {
    @font-face {
        font-family: icon;
        
        src: url("../../fonts/icon.woff2") format("woff2"), url("../../fonts/icon.woff") format("woff"); 
        font-style: normal;
        font-weight: normal;
    }
}

@mixin icon($content: none) {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-family: icon;
    line-height: 1;
    text-transform: none;
    speak: none;
    content: $content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon:before {
    @include icon;
}

.icon-arrow-bold-down:before { content: $icon-arrow-bold-down; }
.icon-arrow-bold-left:before { content: $icon-arrow-bold-left; }
.icon-arrow-bold-right:before { content: $icon-arrow-bold-right; }
.icon-arrow-bold-up:before { content: $icon-arrow-bold-up; }
.icon-external:before { content: $icon-external; }
.icon-facebook:before { content: $icon-facebook; }
.icon-instagram:before { content: $icon-instagram; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-twitter:before { content: $icon-twitter; }
.icon-youtube:before { content: $icon-youtube; }
.icon-mail:before { content: $icon-mail; }
.icon-pointer:before { content: $icon-pointer; }
.icon-telephone:before { content: $icon-telephone; }
