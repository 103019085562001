﻿.shortcut {
	height: rem(630px);
	display: flex;
	flex-wrap: wrap;
	color: inherit;
	text-decoration: none;
	text-align: center;
	background: #fff;
	transition: background 0.2s ease-in-out;
	font-size: rem(16px);

	&__column {

		@include bp-min(s) {

			&:nth-child(even) {

				.shortcut__image-container {
					order: 1;
				}

				.shortcut__content-container {

					&:after {
						top: auto;
						bottom: rem(-80px);
						background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
					}
				}
			}
		}
	}

	&:hover {

		.shortcut__title i {
			left: rem(18px);
		}

		.shortcut__link {
			text-decoration: underline;
		}
	}

	&__image-container {
		position: relative;
		width: 100%;
		height: rem(315px);
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&__content-container {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		height: rem(315px);

		&:after {
			position: absolute;
			z-index: 1;
			content: '';
			width: 100%;
			height: rem(80px);
			top: rem(-80px);
			left: 0;
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
			opacity: 1;
			transition: opacity 0.3s ease-in-out;
		}
	}

	&__content {
		width: 100%;
		padding: 0 rem(20px) 0; //rem(55px) rem(20px) rem(32px);
		@include bp-min(xs) {
			padding: 0 rem(50px) 0;
		}
	}

	&__title {
		margin: 0 0 rem(13px);
		font-size: rem(20px);
		line-height: rem(26px);
		color: $base-text-color;
		text-transform: uppercase;

		i {
			position: relative;
			font-size: rem(12px);
			left: rem(14px);
			bottom: rem(2px);
			transition: left 0.2s ease-in-out;
		}
	}

	&__text {
		margin: 0 0 rem(24px);
	}

	&__link {
		color: $color-primary;
		font-weight: bold;

		&:hover {
			text-decoration: underline;
		}
	}
}
