﻿.overiew-list {
	@extend .side-line;
	@extend .-primary;
	@extend .side-line__padding;
	margin: 0 0 rem(9px);

	&__list {
		@extend .no-bullets;
	}

	&__item {

		&:last-child {
			border-bottom: 1px solid $color-border;
		}
	}

	&__link {
		display: block;
		text-decoration: none;
		color: inherit;
		padding: rem(7px) 0 rem(9px);
		border-top: 1px solid $color-border;
		background: transparent;
		transition: background 0.2s ease-in-out;

		&:hover {
			background: rgba($color-primary, 0.2);
		}

		@include bp-min(xs) {
			display: flex;
		}
	}

	&__date {
		display: block;
		color: #999;
		margin: 0 rem(20px) 0 0;
		min-width: rem(182px);

		@include bp-min(xs) {
			display: inline-block;
		}
	}

	&__name {
		color: $color-primary;
	}
}
