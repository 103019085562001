﻿.extra-text {

	table {
		margin: 0;
	}

	td {
		padding: 0;

		&:first-child {
			width: rem(146px);
		}

		+ td {
			padding-left: rem(20px);
		}
	}
}