﻿.content-block {
	position: relative;
	padding: rem(86px) 0 rem(75px);

	&:nth-child(even) {
		background: $color-gray;
	}

	&.-form {
		background: url(../../images/header-background.jpg) no-repeat center bottom;

		h2 {
			color: #fff;
		}
	}

	&__content {
		position: relative;

		@extend .side-line;
		@extend .-primary;
		@extend .side-line__padding;

		.-form & {
			@extend .-white;
		}
	}

	&__back-to-overview {
		position: absolute;
		top: rem(-61px);
		right: rem(20px);
	}
}
